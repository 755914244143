import gallery01 from "../assets/gallery01.jpeg";
import gallery02 from "../assets/gallery02.jpeg";
import gallery03 from "../assets/gallery03.jpeg";
import gallery04 from "../assets/gallery04.jpeg";
import gallery05 from "../assets/gallery05.jpeg";
import gallery06 from "../assets/gallery06.jpeg";
import gallery07 from "../assets/gallery07.jpeg";
import gallery08 from "../assets/gallery08.jpeg";
import gallery09 from "../assets/gallery09.jpeg";
import gallery10 from "../assets/gallery10.jpeg";
import gallery11 from "../assets/gallery11.jpeg";
import gallery12 from "../assets/gallery12.jpeg";
import eucalyptys from "../assets/eucalyptys.png";
import gastvrouwen from "../assets/gastvrouwen.jpeg";
import logo from "../assets/logo_borgh-hoeve.jpeg";
import tarieven from "../assets/tarieven.jpeg";
import accommodatie01 from "../assets/accommodatie01.jpeg";
import accommodatie02 from "../assets/accommodatie02.jpeg";
import accommodatie03 from "../assets/accommodatie03.jpeg";
import zwfoto from "../assets/zw-foto-borghhoeve01.jpeg";
import location01 from "../assets/oudekerk-ad-ijssel.jpg";
import location02 from "../assets/deSpil.jpg";
import location03 from "../assets/oudekerk-ad-ijssel-2.jpg";
import location04 from "../assets/oudekerk-ad-ijssel-3.jpg";
import welcome from "../assets/HomePagina.jpeg";
import findus from "../assets/fotocollectie01.jpeg";
import instagram from "../assets/instagram-account.jpeg";
import beginquote from "../assets/quote-begin.png";
import endquote from "../assets/quote-end.png";
import introPoster from "../assets/introPoster.jpg";

const images = {
	gallery01,
	gallery02,
	gallery03,
	gallery04,
	gallery05,
	gallery06,
	gallery07,
	gallery08,
	gallery09,
	gallery10,
	gallery11,
	gallery12,
	eucalyptys,
	gastvrouwen,
	logo,
	tarieven,
	accommodatie01,
	accommodatie02,
	accommodatie03,
	zwfoto,
	location01,
	location02,
	location03,
	location04,
	welcome,
	findus,
	instagram,
	beginquote,
	endquote,
	introPoster,
};

export default images;
