import SubHeading from "../SubHeading/SubHeading";
import "./DateSearch.css";

import { GoCalendar } from "react-icons/go";
import { AiFillCloseCircle } from "react-icons/ai";

import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css";
import { differenceInCalendarDays } from "date-fns";
import { format } from "date-fns";

import { useState, useEffect } from "react";

import Axios from "axios";

// aray with unavailable Dates
let listDisabledDates = [new Date()];
let responseDate = [new Date()];

let usedDates = [];
let dateBooked = false;

const DateSearch = () => {
	const [fromDate, setFromDate] = useState(new Date());
	const [toDate, setToDate] = useState(new Date());

	const [dates, setDates] = useState([new Date(), new Date()]);
	const [openDate, setOpenDate] = useState(false);
	const [openMsg, setOpenMsg] = useState(false);

	const [buttonDisable, setButtonDisable] = useState(true);

  useEffect(() => {
    Axios.get(
      "https://deborghhoeve-serverless-backend.netlify.app/.netlify/functions/getDateList"
    ).then((response) => {
      handleResponse(response);
    });
  }, []);

	function handleCalendarChange(dates) {
		setDates(dates);
		setFromDate(dates[0]);
		setToDate(dates[1]);
		setButtonDisable(false);
	}

	async function retrieveDates() {
		if (!openDate) {
			await Axios.get(
				"https://deborghhoeve-serverless-backend.netlify.app/.netlify/functions/getDateList"
			).then((response) => {
				handleResponse(response);
			});
		}
		setOpenDate(!openDate);
	}

	function handleResponse(response) {
		responseDate = response.data;
		listDisabledDates = [];
		responseDate.forEach((d) => {
			listDisabledDates.push(new Date(d));
		});
	}

	const handleCheck = (event) => {
		event.preventDefault();

		setFromDate(new Date(fromDate));
		setToDate(new Date(toDate));

		setOpenDate(false);
		checkBookedDates(fromDate, toDate);

		setDates(null);

		setOpenDate(false);
		setOpenMsg(true);
		setButtonDisable(true);
	};

	const checkBookedDates = (fromDate, toDate) => {
		const end = new Date(toDate);
		usedDates = [];
		dateBooked = false;

		for (
			let checkDate = new Date(fromDate);
			checkDate <= end;
			checkDate.setDate(checkDate.getDate() + 1)
		) {
			if (isDateinRange(listDisabledDates, checkDate) === true) {
				dateBooked = true;
				usedDates.push(new Date(checkDate));
			}

			if (dateBooked === true) {
			}
		}
	};

	function isDateinRange(dates, date) {
		let hasMatch = false;
		const match = dates.find((d) => d.toLocaleDateString() === date.toLocaleDateString());
		if (match){ hasMatch = true};
		return hasMatch;
		}

	function disableDates({ date, view }) {
		if (view === "month") {
			return listDisabledDates.find((dDate) => isSameDay(dDate, date));
		}
	}

	function isSameDay(a, b) {
		return differenceInCalendarDays(a, b) === 0;
	}

	return (
		<div className="app__DateSearch">
			<div className="app__DateSearch-heading">
				<SubHeading title="Beschikbaarheid" />
				<div className="app__DateSearch-subHeading-line"></div>
				<h1 className="headtext__cormorant">Check beschikbaarheid!</h1>
				<p className="p__opensans">
					Selecteer gewenste check-in en check-out data voor beschikbaarheid.
					Hierna kunt u de reservering telefonisch of via email regelen.
				</p>
			</div>
			<div className="app__DateSearch-elements flex__center">
				{openMsg && (
					<div className="app__DateSearch-msgbox">
						<div className="app__DateSearch-msgbox_elements">
							{!dateBooked && (
								<div className="app__DateSearch-msgbox_title p__cormorant">
									<span className="app__DateSearch-msgbox_title_text">
										Welkom!
									</span>
									<button className="app__DateSearch-msgBox_exit_icon">
										<AiFillCloseCircle onClick={() => setOpenMsg(false)} />
									</button>
								</div>
							)}
							{dateBooked && (
								<div className="app__DateSearch-msgbox_title p__cormorant">
									<span className="app__DateSearch-msgbox_title_text">
										Helaas niet beschikbaar!
									</span>
									<button className="app__DateSearch-msgBox_exit_icon">
										<AiFillCloseCircle onClick={() => setOpenMsg(false)} />
									</button>
								</div>
							)}

							{!dateBooked && (
								<div className="app__DateSearch-msgbox_text">
									<p>De gewenste verblijfsperiode</p>
									<h4>
										{"van "}
										{format(fromDate, "dd/MM/yyyy")} {"tot "}
										{format(toDate, "dd/MM/yyyy")}
									</h4>
									<p>is beschikbaar.</p>
									<p>
										<br />U kunt de reservering telefonisch of via email
										regelen.
									</p>
									<p>
										<br />
										M: (+31) 06 58 01 56 52
									</p>
									<p>E: info@deborghhoeve.nl</p>
								</div>
							)}

							{dateBooked && (
								<div className="app__DateSearch-msgbox_text">
									<p>De gewenste verblijfsperiode</p>
									<h4>
										{"van "}
										{format(fromDate, "dd/MM/yyyy")} {"tot "}
										{format(toDate, "dd/MM/yyyy")}
									</h4>
									<p>is helaas niet beschikbaar.</p>
									<p>
										<br />U kunt de beschikbaarheid checken en een
										aaneengesloten beschikbaare periode selecteren.
									</p>
									<p> </p>
									<p>
										<br />
										Voor meer informatie kunt u ons telefonisch of via email
										bereiken.
									</p>
									<p>M: (+31) 06 58 01 56 52</p>
									<p>E: info@deborghhoeve.nl</p>
								</div>
							)}
						</div>
					</div>
				)}
				<div className="app__DateSearch-text">
					<div className="app__DateSearch-calendar_icon">
						<GoCalendar
							onClick={() => {
								setOpenDate(!openDate);
							}}
						/>
					</div>
					<div className="app__DateSearch-text-dates" onClick={retrieveDates}>
						{`van 
						${format(fromDate, "dd/MM/yyyy")} tot
						${format(toDate, "dd/MM/yyyy")}`}
					</div>
					<button
						disabled={buttonDisable}
						type="button"
						className="custom__button"
						onClick={handleCheck}
					>
						Check
					</button>
				</div>
				{openDate && (
					<div className="app__DateSearch-calendar">
						<Calendar
							nextLabel={"m>"}
							next2Label={"  j>>"}
							prevLabel={"<m"}
							prev2Label={"<<j  "}
							onChange={handleCalendarChange}
							value={dates}
							minDate={new Date()}
							selectRange={true}
							tileDisabled={disableDates}
							tileClassName={"app__DateSearch-calandar-tile"}
						/>
					</div>
				)}
			</div>
		</div>
	);
};

export default DateSearch;
