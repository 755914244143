import React from "react";

import { SubHeading } from "../../components";
import { images } from "../../constants";
import "./Accommodatie.css";

const Accommodatie = () => (
	<div
		className="app__Accommodatie app__wrapper section__padding"
		id="accomodation"
	>
		<div className="app__Accommodatie-title">
			<SubHeading title="Comfortabel & Sfeervol" />
			<div className="app__Accommodatie-subHeading-line"></div>
			<h1 className="headtext__cormorant">Accommodatie & Faciliteiten</h1>
		</div>

		<div className="app__Accommodatie-content p__opensans section__padding">
			<div
				className="app__Accommodatie-img"
				style={{
					marginBottom: "0.8rem",
				}}
			>
				<img
					src={images.accommodatie01}
					alt="de borgh hoeve - Accommodatie__img"
				/>
			</div>

			<div className="app__ccomodatie-content_info">
				<p style={{ fontWeight: "300" }}>
					De Borgh-Hoeve is een 2-4 persoons vakantiehuis | B&B
				</p>
				<div
					style={{
						border: "solid",
						borderColor: "var(--color-grey)",
						paddingTop: "1rem",
					}}
				>
					<div
						style={{
							paddingLeft: "3rem",
							paddingRight: "1rem",
							maxWidth: "650px",
						}}
					>
						<ul>
							<li>1 x lits-jumeaux electrisch verstelbaar</li>
							<li>1 x 2 persoons slaapbank</li>
							<li>Badkamer met inloopdouche en toilet</li>
							<li>Ruime living</li>
							<li>
								Compleet uitgeruste keuken, vaatwasser, inductiekookplaat,
								koelkast, oven en magnetron
							</li>
							<li>Eigen ingang, tuin en terras in oude boomgaard</li>
							<li>Gratis Wi-Fi</li>
							<li>Boeken/tijdschriften/spelletjes</li>
							<li>Toeristische informatie</li>
							<li>1 pakket 'linnen' (bevat bed-, bad- en keukenlinnen)</li>
							<li>Exclusief ontbijt</li>
						</ul>
						<p
							style={{
								marginTop: "1.5rem",
								textIndent: "-2.2rem",
								fontWeight: "300",
							}}
						>
							Ontbijt:
						</p>
						<ul>
							<li>
								Wij serveren een uitgebreid ontbijt met diverse streekproducten.
							</li>
						</ul>
						<div
							style={{
								marginTop: "2.5rem",
								textIndent: "-2.2rem",
								fontWeight: "300",
								fontSize: "90%",
							}}
						>
							<i>
								<p>Ontvangst met welkomstdrankje en wat lekkers.</p>
								<p>Onbeperkt koffie en thee.</p>
							</i>
						</div>
						<div className=" app__ccomodatie-content_note">
							<p
								style={{
									borderTop: "2px solid ",
									marginLeft: "-2.2rem",
									padding: "0.3rem",
								}}
							></p>
							<p>(elektrische) fietsen zijn te huur bij</p>
							<p style={{ paddingLeft: "1rem" }}>
								-- Fietswereld Noorlander --
							</p>
							<p>Dorpstraat 58 </p>
							<p>2931 AD Ouderkerk aan den IJssel </p>
							<p> info@fietswereld-noorlander.nl </p>
							<p>T: 0180-681058 </p>
						</div>
					</div>
				</div>
				<p
					style={{
						marginTop: "0.3rem",
						marginBottom: "0.8rem",
						fontWeight: "300",
					}}
				>
					* Geen huisdieren toegestaan.
				</p>
			</div>

			<div
				className="app__Accommodatie-img"
				style={{
					marginTop: "0.8rem",
				}}
			>
				<img
					src={images.accommodatie02}
					alt="de borgh hoeve - Accommodatie__img"
				/>
			</div>
		</div>
	</div>
);

export default Accommodatie;
