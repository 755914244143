import React from "react";

import "./TarievenItem.css";

const tarievenItem = ({ title, price, tags }) => (
	<div className="app__tarievenitem">
		<div className="app__tarievenitem-head">
			<div className="app__tarievenitem-name">
				<p
					className="p__cormorant"
					style={{ color: "#DCCA87", textTransform: "none" }}
				>
					{title}
				</p>
			</div>
			<div className="app__tarievenitem-dash" />
			<div className="app__tarievenitem-price">
				<p className="p__cormorant">{price}</p>
			</div>
		</div>

		<div className="app__tarievenitem-sub">
			<p className="p__opensans">{tags}</p>
		</div>
	</div>
);

export default tarievenItem;
