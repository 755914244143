import React from "react";
// import { FiFacebook, FiTwitter, FiInstagram } from "react-icons/fi";
import { FiFacebook, FiInstagram } from "react-icons/fi";
// import { BiCopyright } from "react-icons/bi";

import { AvailabilityOverlay, DateSearch } from "../../components";
import "./Availability.css";

const Availability = () => (
	<div className="app__Availability section__padding" id="availability">
		<AvailabilityOverlay />
		<DateSearch />

		<div className="app__Availability-links">
			<div className="app__Availability-links_contact">
				<h1 className="app__Availability-headtext">Contact</h1>
				<p className="p__opensans">
					271 IJsseldijk Noord, 2935 BR, Ouderkerk a/d IJssel
				</p>
				<p className="p__opensans">(+31) 06 58 01 56 52</p>
				<p className="p__opensans" style={{ marginBottom: "2rem" }}>
					E: info@deberghhoeve.nl
				</p>
				<p className="p__opensans" style={{ fontSize: "10px" }}>
					Kvk nummer: 85330957
				</p>
				<p className="p__opensans" style={{ fontSize: "10px" }}>
					Bankrekeningnummer : NL55RABO 0189 2095 93 tnv De Borgh-Hoeve
				</p>
			</div>

			<div className="app__Availability-links_logo">
				{/* <img src={images.gericht} alt="Availability_logo" /> */}
				<h1 style={{ fontSize: "60px" }}> De Borgh-Hoeve</h1>
				<p className="p__opensans">
					&quot;De beste plek voor ontspanning en genieten van de natuur.&quot;
				</p>
				<div className="app__Availability-footer-line"></div>

				<div className="app__Availability-links_icons">
					<FiFacebook />
					{/* <FiTwitter /> */}
					<FiInstagram />
				</div>
			</div>

			<div className="app__Availability-links_work">
				<h1 className="app__RegisterDates-headtext">Bereikbaarheid</h1>
				<p className="p__opensans">Telefonisch: maandag-zaterdag</p>
				<p className="p__opensans">of per mail</p>
			</div>
		</div>

		{/* <div
			className="Availability__copyright p__opensans"
			style={{ fontSize: "10px" }}
		>
			<BiCopyright />
			<p>2022 De Borgh-hoeve. alle rechten voorbehouden.</p>
		</div> */}
	</div>
);

export default Availability;
