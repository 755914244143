import images from "./images";

const verblijven = [
  {
    title: "B&B per nacht",
    price: "€100",
    tags: "Aankomst na 14:00 uur | Vertrek voor 11:00",
  },
  {
    title: "Weekend",
    price: "€300",
    tags: "Aankomst vrijdagmiddag | Vertrek maandagmorgen",
  },
  {
    title: "Midweek",
    price: "€350",
    tags: "Aankomst maandagmiddag | Vertrek vrijdagochtend ",
  },
  {
    title: "Week",
    price: "€600",
    tags: "Aankomst vrijdagmiddag | Vertrek vrijdagochtend",
  },
];

const extras = [
  {
    title: "Ontbijt p.p.",
    price: "€12,50",
    tags: "Gelieve bij boeking gewenste aantal doorgeven",
  },
  {
    title: "Extra persoon per overnachting",
    price: "€17,50",
    tags: "",
  },
  {
    title: "Extra pakket linnen",
    price: "€10,00",
    tags: "",
  },
  {
    title: "Toeristenbelasting p.p.p.n",
    price: "€ 1,92",
    tags: "",
  },
];

const locations = [
  {
    imgUrl: images.location01,
    title: "Krimpenerwaard",
    subtitle1:
      "Groene polders met koeien, knotwilgen en slootjes. Maar ook charmante dorpskernen met fraaie monumenten en musea.",
    subtitle2: "",
    locURL: "www.indeKrimpenerwaard.nl",
    locURL_link: "https://www.indeKrimpenerwaard.nl",
  },
  {
    imgUrl: images.location02,
    title: "Gouda",
    subtitle1:
      "Historische binnenstad met een eeuwenoude geschiedenis. Sprookjesachtig stadhuis, prachtige oude kerk, sfeervolle grachten en gezellige terrasjes.",
    subtitle2: "",
    locURL: "www.welkomingouda.nl",
    locURL_link: "https://www.welkomingouda.nl",
  },
  {
    imgUrl: images.location03,
    title: "Rotterdam",
    subtitle1:
      "Bruisende stad vol met iconische bouwwerken. Ook tal van musea, bijzondere markten en groene parken, de stad biedt voor elk wat wils.",
    subtitle2: "",
    locURL: "www.rotterdam.info",
    locURL_link: "https://www.rotterdam.info",
  },
  {
    imgUrl: images.location04,
    title: "Dordrecht",
    subtitle1:
      "Hollands oudste stad wat door zijn verbonden grachten lijkt op Nederlands Venetië. Een stad met ruim 1000 (rijks)monumenten en grenzend aan Nationaal Park de Biesbosch.",
    subtitle2: "",
    locURL: "www.indordrecht.nl",
    locURL_link: "https://www.indordrecht.nl",
  },
  {
    imgUrl: "",
    title: "Kinderdijk",
    subtitle1:
      "De dijken, molens en gemalen van de Kinderdijk zijn zeker een bezoek waard. Al ruim 700 jaar houden ze dit gebied bewoonbaar.",
    subtitle2: "",
    locURL: "www.kinderdijk.nl",
    locURL_link: "https://www.kinderdijk.nl",
  },
  {
    imgUrl: "",
    title: "Golfbaan Crimpenerhout",
    subtitle1:
      "Prachtig aangelegde baan in een waterrijk gebied. Ontworpen door de bekende Belgische baanarchitect Bruno Steensels.",
    subtitle2: "",
    locURL: "www.golfbaancrimpenerhout.nl",
    locURL_link: "https://www.golfbaancrimpenerhout.nl",
  },
];

const photos = [
  {
    imgUrl: images.gallery01,
    title: "Oudekerk aan de IJssel",
  },
  {
    imgUrl: images.gallery02,
    title: "Oudekerk aan de IJssel",
  },
  {
    imgUrl: images.gallery03,
    title: "Oudekerk aan de IJssel",
  },
  {
    imgUrl: images.gallery04,
    title: "Oudekerk aan de IJssel",
  },
  {
    imgUrl: images.gallery05,
    title: "Oudekerk aan de IJssel",
  },
  {
    imgUrl: images.gallery06,
    title: "Oudekerk aan de IJssel",
  },
  {
    imgUrl: images.gallery07,
    title: "Oudekerk aan de IJssel",
  },
  {
    imgUrl: images.gallery08,
    title: "Oudekerk aan de IJssel",
  },
  {
    imgUrl: images.gallery09,
    title: "Oudekerk aan de IJssel",
  },
  {
    imgUrl: images.gallery10,
    title: "Oudekerk aan de IJssel",
  },
  {
    imgUrl: images.gallery11,
    title: "Oudekerk aan de IJssel",
  },
  {
    imgUrl: images.gallery12,
    title: "Oudekerk aan de IJssel",
  },
];

const data = { verblijven, extras, locations, photos };

export default data;
