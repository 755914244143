import React from "react";

import { SubHeading } from "../../components";
import { images } from "../../constants";
import "./FindUs.css";

const FindUs = () => (
	<div className="app__bg app__wrapper section__padding" id="contact">
		<div className="app__wrapper_info">
			<SubHeading title="Zo bereikt u ons" />
			<div className="subHeading-line"></div>
			<h1 className="headtext__cormorant app__FindUs-header">Contact</h1>
			<div className="app__wrapper-content p__cormorant">
				<p className="app__FindUs-content">Adres</p>
				<p className="p__opensans">
					IJsseldijk Noord 271, 2935 BR, Ouderkerk a/d IJssel
				</p>
				<p className="app__FindUs-content">Telefoonnummer</p>
				<p className="p__opensans">(+31) 06 58 01 56 52</p>
				<p className="p__opensans">
					Bereikbaarheid: Telefonisch maandag-zaterdag of per mail
				</p>
				<p className="app__FindUs-content">E-mail</p>
				<p className="p__opensans">info@deborghhoeve.nl</p>
				<a
					href="https://www.instagram.com/de_borgh_hoeve/?igshid=YmMyMTA2M2Y%3D"
					target="_blank"
					rel="noreferrer"
				>
					<img
						width="25%"
						src={images.instagram}
						alt="de borgh hoeve - instagram_account_img"
					/>
				</a>

				{/* <button
					type="button"
					className="custom__button"
					style={{ marginTop: "2rem" }}
				>
					Bezoek ons
				</button> */}
			</div>
		</div>

		<div className="app__wrapper_img app__FindUs-images">
			<img src={images.findus} alt="de borgh hoeve - findus_img" />
		</div>
	</div>
);

export default FindUs;
