import React from "react";

import { SubHeading } from "../../components";
import { images } from "../../constants";
import "./Historie.css";

const Historie = () => (
	<div className="app__bg app__wrapper section__padding">
		<div className="app__wrapper_img app__wrapper_img-reverse">
			<img
				style={{ borderRadius: "1rem" }}
				src={images.zwfoto}
				alt="de borgh hoeve - Historie_image"
			/>
		</div>
		<div className="app__wrapper_info">
			<SubHeading title="Oude karakteristieke boerderij" />
			<div className="subHeading-line"></div>

			<h1 className="headtext__cormorant">Historie Borgh-Hoeve</h1>

			<div className="app__historie-content">
				<div className="app__historie-content_quote p__opensans">
					<p>
						{/* <img
							src={images.beginquote}
							alt="de borgh hoeve - quote_image"
							className="app__historie-content_beginquote"
						/> */}
						{/* <p> */}
						Deze boerderij is zeer waarschijnlijk gebouwd in 1840. In de jaren
						’70 heeft hier een kunstenaar gewoond die de boerderij naar zichzelf
						heeft genoemd… De Borgh-Hoeve
					</p>
					<p>Deze naam wordt tot op de dag van vandaag gehanteerd.</p>
					<br />
					<p>
						Het vakantiehuis/B&B bevindt zich in het voorhuis en het
						privégedeelte in het achterhuis. Op de plek waar nu een mooie
						pluktuin is gecreëerd, stond vroeger het zogenaamde zomerhuis.
						{/* </p> */}
						{/* <img
							src={images.endquote}
							alt="de borgh hoeve - quote_image"
							className="app__historie-content_endquote"
						/> */}
					</p>
				</div>
			</div>
		</div>
	</div>
);

export default Historie;
