import {
	AboutUs,
	Accommodatie,
	Availability,
	FindUs,
	Footer,
	Gallery,
	Historie,
	Header,
	Intro,
	NearBy,
	Tarieven,
} from "../../container";
import { Navbar } from "../../components";

const Main = () => (
	<div>
		<Navbar />
		<Header />
		<AboutUs />
		<Accommodatie />
		<Tarieven />
		<Historie />
		<Intro />
		<NearBy />
		<Gallery />
		<FindUs />
		<Availability />
		<Footer />
	</div>
);

export default Main;
