import React from "react";
import { GiHamburgerMenu } from "react-icons/gi";
// import { MdOutlinetarievenOpen } from "react-icons/md";
import { MdOutlineHotel } from "react-icons/md";
// import { MdOutlineHome } from "react-icons/md";
import images from "../../constants/images";
import "./Navbar.css";

const Navbar = () => {
	const [togglemenu, setTogglemenu] = React.useState(false);
	return (
		<nav className="app__navbar">
			<div className="app__navbar-logo">
				<img src={images.logo} alt="de borgh hoeve - app__logo" />
				<div className="app__navbar-logo_text">
					<h1 className="p__app_navbar-logo_text-header"> De Borgh-Hoeve</h1>
					<h1 className="p__app_navbar-logo_text-sub"> Vakantiehuis | B&B</h1>
				</div>
			</div>
			<ul className="app__navbar-links">
				<li className="p__opensans">
					<a href="#home">Home</a>
				</li>
				<li className="p__opensans">
					<a href="#about">Wie wij zijn</a>
				</li>
				<li className="p__opensans">
					<a href="#accomodation">Accommodatie</a>
				</li>
				<li className="p__opensans">
					<a href="#locations">Omgeving</a>
				</li>
				<li className="p__opensans">
					<a href="#contact">Contact</a>
				</li>
				<div className="app__navbar-login">
					{/*
					<a href="#login" className="p__opensans">
					Log In / Registratie
					</a>
 				*/}

					{/* <a href="#registerDates" className="p__opensans">
					Datums Blokkeren
				</a> */}

					<div />
					<a href="#availability" className="p__opensans">
						Beschikbaarheid
					</a>
				</div>
			</ul>
			<div className="app__navbar-smallscreen">
				<GiHamburgerMenu
					color="#fff"
					fontSize={27}
					onClick={() => setTogglemenu(true)}
				/>
				{togglemenu && (
					<div className="app__navbar-smallscreen_overlay flex__center slide-bottom">
						<div className="navbar-smallscreen_header">
							<div className="app__navbar-logo">
								<img src={images.logo} alt="de borgh hoeve - app__logo" />
								<div className="app__navbar-logo_text">
									<h1 className="p__app_navbar-logo_text-header">
										De Borgh-Hoeve
									</h1>
									<h1 className="p__app_navbar-logo_text-sub">
										Vakantiehuis | B&B
									</h1>
								</div>
							</div>
							{/* <div className="overlay__close_link">
								<a href="#availability" onClick={() => setTogglemenu(false)}>
									Beschikbaarheid
								</a>
							</div>
							<div className="navbar-smallscreen_header-line"></div> */}
							<div className="overlay__close">
								<MdOutlineHotel onClick={() => setTogglemenu(false)} />
							</div>
						</div>
						<ul className="app__navbar-smallscreen_links">
							<li>
								<a href="#home" onClick={() => setTogglemenu(false)}>
									Home
								</a>
							</li>
							<li>
								<a href="#about" onClick={() => setTogglemenu(false)}>
									Wie wij zijn
								</a>
							</li>
							<li>
								<a href="#accomodation" onClick={() => setTogglemenu(false)}>
									Accommodatie
								</a>
							</li>
							<li>
								<a href="#locations" onClick={() => setTogglemenu(false)}>
									Omgeving
								</a>
							</li>
							<li>
								<a href="#contact" onClick={() => setTogglemenu(false)}>
									Contact
								</a>
							</li>
							<li>
								<a href="#availability" onClick={() => setTogglemenu(false)}>
									Beschikbaarheid
								</a>
							</li>
						</ul>
					</div>
				)}
			</div>
		</nav>
	);
};

export default Navbar;
