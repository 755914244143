import React from "react";

import { SubHeading } from "../../components";
import { images } from "../../constants";
import "./Header.css";

const Header = () => (
	<div className="app__header app__wrapper section__padding" id="home">
		<div className="app__wrapper_info">
			<SubHeading title="Even weg uit het gehaaste leven?" />
			<div
				className="subHeading-line"
				style={{
					background: "var(--color-grey)",
					width: "120px",
					height: "10px",
				}}
			></div>

			<h1 className="app__header-h1">"Verborgen parel in de Krimpenerwaard"</h1>
			<p className="p__opensans" style={{ margin: "2rem 0" }}>
				De plek om tot rust te komen en weer op te laden. Omgeven door groen en
				water waant u zich ver van de bewoonde wereld.
			</p>
			{/* bewaren! */}
			{/* <button type="button" className="custom__button">
				Verkennen
			</button> */}
		</div>

		<div className="app__wrapper_img">
			<img src={images.welcome} alt="de borgh hoeve - header_img" />
		</div>
	</div>
);

export default Header;
