import React from "react";
import {
	// BsInstagram,
	BsArrowLeftShort,
	BsArrowRightShort,
} from "react-icons/bs";

// import { SubHeading } from "../../components";
import { data } from "../../constants";
import "./Gallery.css";

const Gallery = () => {
	const scrollRef = React.useRef(null);

	const scroll = (direction) => {
		const { current } = scrollRef;

		if (direction === "left") {
			current.scrollLeft -= 300;
		} else {
			current.scrollLeft += 300;
		}
	};

	return (
		<div className="app__gallery flex__center">
			<div className="app__gallery-content">
				{/* <SubHeading title="Instagram" /> */}
				<h1 className="headtext__cormorant">Sfeerimpressie</h1>
				{/* bewaren */}
				{/* <button type="button" className="custom__button">
					Meer..
				</button> */}
			</div>
			<div className="app__gallery-images">
				{/* {data.photos.map((photo) => (( */}
				<div className="app__gallery-images_container" ref={scrollRef}>
					{data.photos.map((image, index) => (
						<div
							className="app__gallery-images_card flex__center"
							key={`gallery_image-${index + 1}`}
						>
							<img src={image.imgUrl} alt="de borgh hoeve - gallery_image" />
							{/* <BsInstagram className="gallery__image-icon" /> */}
						</div>
					))}
				</div>
				<div className="app__gallery-images_arrows">
					<BsArrowLeftShort
						className="gallery__arrow-icon"
						onClick={() => scroll("left")}
					/>
					<BsArrowRightShort
						className="gallery__arrow-icon"
						onClick={() => scroll("right")}
					/>
				</div>
			</div>
		</div>
	);
};

export default Gallery;
