import React from "react";

import { images } from "../../constants";
import "./AboutUs.css";

const AboutUs = () => (
	<div
		className="app__aboutus app__bg flex__center section__padding"
		id="about"
	>
		<div className="app__aboutus-content flex__center">
			<div className="app__aboutus-content_about">
				<h1 className="headtext__cormorant">Wie zijn wij</h1>
				<div className="subHeading-line"></div>
				<p className="p__opensans">
					Welkom op de Borgh-Hoeve! Wij zijn moeder (Mariëlle) en dochter
					(Joanne). Onze lang gekoesterde wens was om samen iets te ondernemen
					op deze prachtige plek. Na een tijdje wikken en wegen hebben we het
					besluit genomen om het gewoon te proberen. Dat ‘proberen’ is na enige
					maanden klussen bijzonder goed gelukt, al zeggen we het zelf!! We
					hopen dat je net zo enthousiast zult zijn als wij. Oordeel zelf!
				</p>
				{/* bewaren! */}
				{/* <button type="button" className="custom__button">
					Meer weten...
				</button> */}
			</div>

			<div className="app__aboutus-content_image flex__center">
				<img src={images.eucalyptys} alt="de borgh hoeve - about_image" />
			</div>

			<div className="app__wrapper_img app__wrapper_img-reverse app__aboutus-content_guesthost">
				<div className="app__aboutus-content_guesthost-image">
					<img
						src={images.gastvrouwen}
						alt="de borgh hoeve - GuestHost_image"
					/>
				</div>
				<div className="app__aboutus-content_GuestHost-sign">
					<p>Mariëlle & Joanne Punt</p>
					<p className="p__opensans">Gastvrouwen</p>
				</div>
			</div>
		</div>
	</div>
);

export default AboutUs;
