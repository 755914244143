import React from "react";
import { useState, useEffect } from "react";

import SubHeading from "../SubHeading/SubHeading";
import "./RegisterDatesCalendar.css";

import { GoCalendar } from "react-icons/go";
import { AiFillCloseCircle } from "react-icons/ai";

import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css";
import { differenceInCalendarDays } from "date-fns";
import { format } from "date-fns";

// import React and DatePicker
import DatePicker from "react-datepicker";
// import required css from library
import "react-datepicker/dist/react-datepicker.css";

import Axios from "axios";
import { nl } from "date-fns/locale";

// aray with unavailable Dates
let listDisabledDates = [new Date()];
let responseDate = [new Date()];

const RegisterDatesCalendar = () => {
  const [fromDate, setFromDate] = useState(new Date(Date()));
  const [toDate, setToDate] = useState(new Date());

  const [dates, setDates] = useState([new Date(), new Date()]);
  const [action, setAction] = useState("");
  const [openDate, setOpenDate] = useState(false);
  const [openMsg, setOpenMsg] = useState(false);

  const [buttonDisable, setButtonDisable] = useState(true);

  function handleCalendarChange(dates) {
    setDates(dates);
    setFromDate(dates[0]);
    setToDate(dates[1]);
    setButtonDisable(false);
  }

  function handleCalendarOnSelect(dates) {
    setDates(dates);
    setFromDate(dates[0]);
    setToDate(dates[1]);
    setButtonDisable(false);
  }

  function handleFromDate(date) {
    setFromDate(date);
    setButtonDisable(false);
    if (toDate < date) {
      setToDate(date);
    }
  }
  function handleToDate(date) {
    setToDate(date);
    setButtonDisable(false);
  }

  function checkToDate(toDate) {
    let isToDateOk = false;
    if (toDate < fromDate) {
      setToDate(fromDate);
      alert(
        `datum 'tot' (${format(
          toDate,
          "dd/MM/yyyy"
        )}) mag niet lager zijn dan datum 'van' (${format(
          fromDate,
          "dd/MM/yyyy"
        )})`
      );
    } else {
      isToDateOk = true;
    }
    return isToDateOk;
  }

  function handleResponse(response) {
    responseDate = response.data;
    listDisabledDates = [];
    responseDate.forEach((d) => {
      listDisabledDates.push(new Date(d));
    });
  }

  useEffect(() => {
    Axios.get(
      "https://deborghhoeve-serverless-backend.netlify.app/.netlify/functions/getDateList"
    ).then((response) => {
      handleResponse(response);
    });
  }, []);

  const updateDateList = async (dateList) => {
    try {
      await Axios.put(
        "https://deborghhoeve-serverless-backend.netlify.app/.netlify/functions/updateDateList",
        {
          dateList,
        }
      ).then((response) => {
        handleResponse(response);
      });
    } catch (error) {
      return { statusCode: 500, body: error.toString() };
    }
  };

  const addTolistBookedDates = (fromDate, toDate) => {
    const endDate = new Date(toDate);

    for (
      let checkDate = new Date(fromDate);
      checkDate <= endDate;
      checkDate.setDate(checkDate.getDate() + 1)
    ) {
      if (isDateinRange(listDisabledDates, checkDate) !== true) {
        listDisabledDates.push(new Date(checkDate));
      }
    }
    listDisabledDates.sort((a, b) => a - b); // sort blocked dates list

    updateDateList(listDisabledDates);
  };

  const removeFromlistBookedDates = (fromDate, toDate) => {
    const endDate = new Date(toDate);

    for (
      let checkDate = new Date(fromDate);
      checkDate <= endDate;
      checkDate.setDate(checkDate.getDate() + 1)
    ) {
      if (isDateinRange(listDisabledDates, checkDate) === true) {
        listDisabledDates = listDisabledDates.filter(function (d) {
          return (
            new Date(d).toLocaleDateString() !==
            new Date(checkDate).toLocaleDateString()
          );
        });
      }
    }
    updateDateList(listDisabledDates);
  };

  function isDateinRange(dates, date) {
    let hasMatch = false;
    const match = dates.find(
      (d) =>
        new Date(d).toLocaleDateString() === new Date(date).toLocaleDateString()
    );
    if (match){ hasMatch = true};
    return hasMatch;
  }

  function disableDates({ date, view }) {
    if (view === "month") {
      return listDisabledDates.find((dDate) => isSameDay(dDate, date));
    }
  }

  function isSameDay(a, b) {
    return differenceInCalendarDays(a, b) === 0;
  }

  const handleBlockDates = (event) => {
    event.preventDefault();

    if (checkToDate(toDate)) {
      setFromDate(new Date(fromDate));
      setToDate(new Date(toDate));

      setOpenDate(false);
      addTolistBookedDates(fromDate, toDate);

      setOpenDate(false);

      setAction("Block");
      setOpenMsg(true);
    }
    setDates(null);

    setButtonDisable(true);
  };

  const handleUnblockkDates = (event) => {
    event.preventDefault();

    if (checkToDate(toDate)) {
      setOpenDate(false);
      setFromDate(new Date(fromDate));
      setToDate(new Date(toDate));
      removeFromlistBookedDates(fromDate, toDate);

      setOpenDate(false);

      setAction("Unblock");
      setOpenMsg(true);
    }
    setDates(null);
    setButtonDisable(true);
  };

  return (
    <div className="app__RegisterDatesCalendar">
      <div className="app__RegisterDatesCalendar-heading">
        <SubHeading title="Datums bijwerken" />
        <div className="app__RegisterDatesCalendar-subHeading-line"></div>
        {/* <div className="app__RegisterDatesCalendar-subHeading-line"></div> */}
        <h1 className="headtext__cormorant">Beschikbaarheid bijwerken</h1>
        <p className="p__opensans">
          Selecteer gewenste datums en click op 'Blokkeren' om geselecteerde
          datums te blokkeren of op 'Beschikbaar' om ze vrij te geven.
        </p>
      </div>
      <div className="app__RegisterDatesCalendar-elements flex__center">
        {openMsg && (
          <div className="app__RegisterDatesCalendar-msgbox">
            {action === "Block" && (
              <div className="app__RegisterDatesCalendar-msgbox_elements">
                <div className="app__RegisterDatesCalendar-msgbox_title p__cormorant">
                  <span className="app__RegisterDatesCalendar-msgbox_title_text">
                    Periode geblokkeerd!
                  </span>
                  <button className="app__RegisterDatesCalendar-msgBox_exit_icon">
                    <AiFillCloseCircle onClick={() => setOpenMsg(false)} />
                  </button>
                </div>
                <div className="app__RegisterDatesCalendar-msgbox_text">
                  <p>De opgegeven periode </p>
                  <h4>
                    {"van "}
                    {format(fromDate, "dd/MM/yyyy")} {"tot "}
                    {format(toDate, "dd/MM/yyyy")}
                  </h4>
                  <p>is nu geblokeerd!</p>
                  <p>
                    <br />U kunt de blokkering alleen ongedaan maken door
                    gewneste datums weer beschikbaar te stellen.
                  </p>
                  <p>
                    <br />
                  </p>
                  <br />
                </div>
              </div>
            )}
            {action === "Unblock" && (
              <div className="app__RegisterDatesCalendar-msgbox_elements">
                <div className="app__RegisterDatesCalendar-msgbox_title p__cormorant">
                  <span className="app__RegisterDatesCalendar-msgbox_title_text">
                    Periode beschikbaar gesteld!
                  </span>
                  <button className="app__RegisterDatesCalendar-msgBox_exit_icon">
                    <AiFillCloseCircle onClick={() => setOpenMsg(false)} />
                  </button>
                </div>
                <div className="app__RegisterDatesCalendar-msgbox_text">
                  <p>De opgegeven periode </p>
                  <h4>
                    {"van "}
                    {format(fromDate, "dd/MM/yyyy")} {"tot "}
                    {format(toDate, "dd/MM/yyyy")}
                  </h4>
                  <p>is nu vrij gegeven!</p>
                  <p>
                    <br />
                    De opgegeven periode is nu beschikbaar voor reservering.
                  </p>
                  <p>
                    <br />
                  </p>
                  <br />
                </div>
              </div>
            )}
          </div>
        )}
        {!openMsg && (
          <div className="app__RegisterDatesCalendar-text">
            <div className="app__RegisterDatesCalendar-calendar_icon">
              <GoCalendar onClick={() => setOpenDate(!openDate)} />
            </div>
            <div className="app__RegisterDatesCalendar-calendar-datePicker">
              <p>van</p>
              <DatePicker
                locale={nl}
                showMonthDropdown
                dateFormat={"dd/MM/yyyy"}
                selected={fromDate}
                onSelect={(date) => {
                  handleFromDate(date);
                }}
                minDate={new Date()}
                onChange={(date) => {
                  handleFromDate(date);
                }}
              />
              <p>tot</p>
              <DatePicker
                locale={nl}
                showMonthDropdown
                dateFormat={"dd/MM/yyyy"}
                selected={toDate}
                onSelect={(date) => handleToDate(date)}
                onChange={(date) => handleToDate(date)}
              />
            </div>
            <div
              className="app__RegisterDatesCalendar-text-dates"
              onClick={() => setOpenDate(!openDate)}
            >
              {`van 
						${format(fromDate, "dd/MM/yyyy")} tot
						${format(toDate, "dd/MM/yyyy")}`}
            </div>
            <button
              disabled={buttonDisable}
              type="button"
              className="custom__button"
              onClick={handleBlockDates}
            >
              Blokkeren
            </button>
            <button
              disabled={buttonDisable}
              type="button"
              className="custom__button"
              onClick={handleUnblockkDates}
            >
              Beschikbaar
            </button>
          </div>
        )}
        {openDate && (
          <div className="app__RegisterDatesCalendar-calendar">
            <Calendar
              nextLabel={"m>"}
              next2Label={"  j>>"}
              prevLabel={"<m"}
              prev2Label={"<<j  "}
              onSelect={handleCalendarOnSelect}
              onChange={handleCalendarChange}
              value={dates}
              minDate={new Date()}
              selectRange={true}
              tileDisabled={disableDates}
              tileClassName={"app__RegisterDatesCalendar-calandar-tile"}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default RegisterDatesCalendar;
