import React from "react";
import { BsCircle } from "react-icons/bs";

import { SubHeading } from "../../components";
import { images, data } from "../../constants";
import "./NearBy.css";

const LocationCard = ({
	location: { imgUrl, title, subtitle1, subtitle2, locURL, locURL_link },
}) => (
	<div className="app__nearby_locations-card">
		<BsCircle className="app__nearby_locations-card_icon" />
		<div className="app__nearby_locations-card_content">
			<p className="p__cormorant" style={{ color: "#DCCA87" }}>
				{title}
			</p>
			<p className="p__opensans">{subtitle1}</p>
			<p className="p__opensans" style={{ marginTop: "5px" }}>
				{subtitle2}
			</p>

			<div className="p__opensans">
				<a
					href={locURL_link}
					target="_blank"
					rel="noreferrer" // Using target="_blank" without rel="noreferrer" (which implies rel="noopener")
					//  is a security risk in older browsers:
					// see https://mathiasbynens.github.io/rel-noopener/#recommendations  react/jsx-no-target-blank
					className=" app__nearby_locations-card_content-link"
				>
					<p>{locURL}</p>
				</a>
			</div>
		</div>
	</div>
);

const NearBy = () => (
	<div className="app__bg app__wrapper section__padding" id="locations">
		<div className="app__wrapper_info">
			<SubHeading title="De IJssel | Gouda | Fietsen | ...." />
			<div className="subHeading-line"></div>

			<h1 className="headtext__cormorant">Wat te doen in de omgeving</h1>

			<div className="app__nearby_locations">
				{data.locations.map((location) => (
					<LocationCard location={location} key={location.title} />
				))}
			</div>
		</div>

		<div className="app__nearby_img">
			<div className="app__nearby_img-content">
				<img src={images.location01} alt="de borgh hoeve - location01_img" />
				<img src={images.location02} alt="de borgh hoeve - location02_img" />
				<img src={images.location03} alt="de borgh hoeve - location03_img" />
				<img src={images.location04} alt="de borgh hoeve - location04_img" />
			</div>
		</div>
	</div>
);

export default NearBy;
