import { useState } from "react";
import Axios from "axios";
import { Admin, Main } from "./pages";
import "./App.css";

function App() {
  const reqPath = window.location.hash;

  const [reqUrl, setReqUrl] = useState(false);
  let agendaUrl = "";
  let componentName;

  function getComponent() {
    Axios.get(
      "https://deborghhoeve-serverless-backend.netlify.app/.netlify/functions/getAgenda"
    ).then((response) => {
      agendaUrl = response.data.agendaUrl;

      if (reqPath === agendaUrl) {
        setReqUrl("Admin");
      } else {
        setReqUrl(reqPath);
      }
    });
  }

  getComponent();

  switch (reqUrl) {
    case "Admin":
      componentName = <Admin />;
      break;
    case "/":
      componentName = <Main />;
      break;
    default:
      componentName = <Main />;
      break;
  }

  return (
    <div className="App">
      <div>{componentName}</div>
    </div>
  );
}

export default App;
