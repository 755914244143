import React from "react";
import images from "../../constants/images";
import "./AdminNavbar.css";

const AdminNavbar = () => {
	return (
		<nav className="app__adminNavbar">
			<div className="app__adminNavbar-logo">
				<img src={images.logo} alt="de borgh hoeve - app__logo" />
				<div className="app__adminNavbar-logo_text">
					<h1 className="p__app_adminNavbar-logo_text-header">
						De Borgh-Hoeve
					</h1>
					<h1 className="p__app_adminNavbar-logo_text-sub">
						Vakantiehuis | B&B
					</h1>
				</div>
			</div>
		</nav>
	);
};

export default AdminNavbar;
