import { RegisterDates } from "../../container";
import { AdminNavbar } from "../../components";

const Admin = () => (
	<div id="admin">
		<AdminNavbar />
		<RegisterDates />
	</div>
);

export default Admin;
