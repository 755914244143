import React from "react";

import "./RegisterDatesOverlay.css";

const RegisterDatesOverlay = () => (
	<div className="app__RegisterDatesOverlay">
		<div className="app__RegisterDatesOverlay-base" />
		{/* <div className="app__RegisterDatesOverlay-img app__bg" /> */}
	</div>
);

export default RegisterDatesOverlay;
