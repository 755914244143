import React from "react";

import { TarievenItem } from "../../components";
import { data, images } from "../../constants";
import "./Tarieven.css";

const Tarieven = () => (
  <div className="app__Tarieven flex__center section__padding" id="list">
    <div className="app__Tarieven-title">
      <p className="p__cormorant">
        De Borgh-Hoeve heeft plek voor 2-4 personen
      </p>
      <p
        style={{
          width: "20%",
          background: "var(--color-grey)",
          height: "1.5px",
          marginLeft: "40%",
        }}
      />
      <p
        className="p__cormorant"
        style={{
          fontWeight: "300",
        }}
      >
        Onderstaande prijzen zijn op basis van 2 personen. Het minimale verblijf
        is 2 nachten.
      </p>
      <div className="app__Tarieven-subHeading-line"></div>
      <h1 className="headtext__cormorant">Tarieven</h1>
    </div>

    <div className="app__Tarieven-list">
      <div className="app__Tarieven-list_verblijf flex__center">
        <p className="app__Tarieven-list_heading">Verblijf</p>
        <div className="app__Tarieven_list_items">
          {data.verblijven.map((verblijf, index) => (
            <TarievenItem
              key={verblijf.title + index}
              title={verblijf.title}
              price={verblijf.price}
              tags={verblijf.tags}
            />
          ))}
        </div>
      </div>

      <div
        className="app__Tarieven-list_img"
        style={{
          marginTop: "7rem",
        }}
      >
        <img src={images.accommodatie03} alt="de borgh hoeve - list__img" />
      </div>

      <div className="app__Tarieven-list_extras flex__center">
        <p className="app__Tarieven-list_heading">Extra's</p>
        <div className="app__Tarieven_list_items">
          {data.extras.map((extra, index) => (
            <TarievenItem
              key={extra.title + index}
              title={extra.title}
              price={extra.price}
              tags={extra.tags}
            />
          ))}
        </div>
      </div>
    </div>
  </div>
);

export default Tarieven;
