import React from "react";

// import SubHeading from "../SubHeading/SubHeading";
import images from "../../constants/images";
import "./Newsletter.css";

const Newsletter = () => (
	<div className="app__newsletter">
		<div className="app__newsletter-heading">
			<div className="p__cormorant app__newsletter-heading-subheading">
				{/* <p> Reserveren:</p>
				<p> Per mail: info@deborghhoeve.nl </p>
				<p> of </p>
				<p> Telefonisch: (+31) 06 58 01 56 52 </p> */}
				<img src={images.logo} alt="de borgh hoeve - app__logo" />
				<div className="app__newsletter-subHeading-line"></div>
				<h1 className="headtext__cormorant">Welkom!</h1>
				{/* <SubHeading title="Nieuwsbrief" /> */}
				{/* <div className="app__newsletter-subHeading-line"></div> */}
				{/* <h1 className="headtext__cormorant">Meld u aan voor onze nieuwsbrief</h1> */}
				{/* <p className="p__opensans">en mist u nooit de laateste updates!</p> */}
			</div>
		</div>
		{/* <div className="app__newsletter-input flex__center">
			<input type="email" placeholder="Enter uw mailadres" />
			<button type="button" className="custom__button">
				Aanmelden
			</button>
		</div> */}
	</div>
);

export default Newsletter;
