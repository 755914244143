import React from "react";

import "./AvailabilityOverlay.css";

const AvailabilityOverlay = () => (
	<div className="app__AvailabilityOverlay">
		<div className="app__AvailabilityOverlay-base" />
		<div className="app__AvailabilityOverlay-img app__bg" />
	</div>
);

export default AvailabilityOverlay;
