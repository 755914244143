import React from "react";
// import { FiFacebook, FiTwitter, FiInstagram } from "react-icons/fi";
// import { FiFacebook, FiInstagram } from "react-icons/fi";
import { BiCopyright } from "react-icons/bi";

import { FooterOverlay, Newsletter } from "../../components";
import "./Footer.css";

const Footer = () => {
	// const handleClickInstagram = () => {
	// 	window.open(
	// 		"https://www.instagram.com/de_borgh_hoeve/?igshid=YmMyMTA2M2Y%3D"
	// 	);
	// };

	// const handleClickFacebook = () => {
	// 	window.open(
	// 		"https://www.facebook.com/people/de-Borgh-hoeve/100086606236944"
	// 	);
	// };

	return (
		<div className="app__footer section__padding" id="footer">
			<FooterOverlay />
			<Newsletter />

			<div className="app__footer-links">
				{/* <div className="app__footer-links_contact">
					<h1 className="app__footer-headtext">Contact</h1>
					<p className="p__opensans">
						IJsseldijk Noord 271, 2935 BR, Ouderkerk a/d IJssel
					</p>
					<p className="p__opensans">(+31) 06 58 01 56 52</p>
					<p className="p__opensans" style={{ marginBottom: "2rem" }}>
						E: info@deborghhoeve.nl
					</p>

					<div className="p__opensans app__footer-headtext-info">
						<p>Kvk nummer: 85330957</p>
						<p>Bankrekeningnummer : NL55RABO 0189 2095 93</p>
						<p style={{ lineHeight: "0" }}>tnv De Borgh-Hoeve</p>
					</div>
				</div>

				<div className="app__footer-links_logo flex__center">
					<h1 style={{ fontSize: "60px" }}> De Borgh-Hoeve</h1>
					<p className="p__opensans">
						&quot;De beste plek voor ontspanning en genieten van de
						natuur.&quot;
					</p>
					<div className="app__Footer-subHeading-line"></div>

					<div className="app__footer-links_icons">
						<FiFacebook onClick={handleClickFacebook} />
						<FiInstagram onClick={handleClickInstagram} />
					</div>
				</div>

				<div className="app__footer-links_work">
					;<h1 className="app__RegisterDates-headtext">Bereikbaarheid</h1>
					<p className="p__opensans">Telefonisch: maandag-zaterdag</p>
					<p className="p__opensans">of per mail</p>
				</div> */}
			</div>

			<div className="p__opensans footer__copyright">
				<div>
					<BiCopyright />
					2022 De Borgh-Hoeve. alle rechten voorbehouden.
				</div>
			</div>
		</div>
	);
};
export default Footer;
